@font-face {
    font-family: "roboto";
    src: url("../fonts/Roboto-Regular.ttf");
    font-display: swap;
  }
  @font-face {
    font-family: "VisbyBold";
    src: url("../fonts/VisbyCF-Bold.otf");
    font-display: swap;
  }
  .parent {
    display: grid;
    grid-template-columns: 30% 40% 30%;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    margin-left: 450px;
    margin-right: 450px;
    background-color: rgb(240, 240, 239, 0.6);
    min-height: 80vh
  }
  .border {
    margin: 0px 15px 0px 15px;
    border-bottom: 1px solid rgb(106, 104, 104);
  }
  .borderBig {
    margin: 0px 0px 0px 200px;
    border-bottom: 2px solid rgb(5, 5, 5);
  }
  .borderBox {
    margin: 0px 15px 0px 0px;
    border-bottom: 1px solid rgb(106, 104, 104);
  }
  .borderBoxLast {
    margin: 0px 15px 10px 0px;
    padding-top: 5px;
    border-bottom: 1px solid rgb(106, 104, 104);
  }
  .borderMiddle {
    margin: 30px 15px 10px 15px;
    border-bottom: 1px solid rgb(106, 104, 104);
  }
  .borderNone {
    display: none;
  }
  .cupLogo {
    margin-right: 20px;
    float: right;
    width: 60px;
    color: yellow;
  }
  .boxesImage {
    width: 50px;
    padding: 5px;
    filter: invert(90%) sepia(24%) saturate(3900%) hue-rotate(358deg)
      brightness(102%) contrast(105%);
  
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  /* leftSide */
  
  .leftSide {
    margin: 30px;
    background-color: rgb(4, 5, 5, 0.8);
  }

  .youtubeDiv{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px;
  }
  .youtubeBread{
    font-family: "roboto";
    font-size: 1rem;
    margin: 5px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .youtubeTitle{
    font-family: "VisbyBold";
    color: #fff;
    margin: 15px;
    font-size: 1rem;
  }
  .itemLink{
    text-decoration: none;
  }
  /*.youTubeImg{
    border: 2px solid #ffdd00;
  } */
  .leftSideBlack {
    margin: 30px;
    background-color: rgb(4, 5, 5, 0.8);
  }
  .leftSide .aikStats {
    display: none;
  }
  .leftSide .emblemImage {
    display: none;
  }
  .leftSide .catchPhrase {
    display: none;
  }
  .flexbox {
    display: flex;
  }
  .leftSide p {
    font-family: "roboto";
    margin-left: 15px;
    margin-right: 15px;
    color: #fff;
  }
  
  .leftSide h3 {
    margin: 2px;
    margin-left: 15px;
  }
  .playerName {
    font-family: addington;
    margin: 15px;
    color: #ffdd00;
  }
  .bigMenu {
    color: #ffdd00;
  }
  .tifoColor{
    color: #ffdd00;
  }
  .links {
    color: #ffdd00;
    text-align: center;
    font-size: 2rem;
    font-family: "VisbyBold";
    margin:5px;
  }
  .smallLink {
    text-decoration: none;
    margin-bottom: 5px;
    color: white;
    display: block;
    font-family: "roboto";
    font-size: 0.8rem;
    text-align: center;
  }
  .smallLinkMassan {
    text-decoration: none;
    margin-bottom: 5px;
    color: #ffdd00;
    font-family: "roboto";
    font-size: 0.8rem;
    text-align: center;
  }
  .smallLink:hover {
    color: #ffdd00;
  }
  .contact {
      text-decoration: none;
  
      color: white;
      display: block;
      font-family: "roboto";
      font-size: 0.8rem;
      text-align: center;
    }
  
  /* leftSide END*/
  
  .rightSide {
  }
  
  .info {
    display: none;
  }
  .rightSideFixtures {
    margin: 10px;
  }
  .fixtureP {
    font-family: "roboto";
    text-align: center;
    margin: 5px;
    color: #fff;
  }
  .matcherH1 {
    font-family: "VisbyBold";
    color: #222;
    text-align: center;
    margin-bottom: 0;
    margin: 0;
  }
  
  .dateSpan {
    background-color: #999;
    border-radius: 30px;
    padding: 1px;
    font-size: 0.8rem;
  }
  
  .yellowSpan {
    color: #ffdd00;
  }
  
  .signing {
    font-family: "roboto";
    font-size: 1rem;
    color: #222;
    margin-top: 10px;
  }
  .aikStats {
    font-family: "VisbyBold";
    color: #222;
    margin: 5px;
    font-size: 4rem;
  }
  
  .catchPhrase {
    font-size: 0.7rem;
    font-family: "roboto";
    margin: 0;
  }
  .infoBox {
    width: auto;
  }
  .emblemImage {
    margin-right: 10px;
    border-radius: 50%;
    float: left;
    border-radius: 200px;
    box-sizing: border-box;
  }
  .selectador {
    display: block;
    margin: 0 auto;
  }
  .nationality {
    margin-top: 40px;
    color: #222;
  }
  
  .rightSide h1 {
    font-size: 2rem;
    margin-top: 40px;
  }
  
  .rightSide h4 {
    font-size: 0.9rem;
    font-weight: normal;
    color: #222;
  }
  .compInfo {
    margin-top: 60px;
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .leftBox {
  }
  .swedishCupBox {
    margin: 5px;
    min-height: 200px;
    text-align: left;
    justify-content: left;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .cupTitle {
    font-family: "VisbyBold";
    font-size: 1.4rem;
    color: #ffdd00;
    margin: 5px;
  }
  .swedishCupBox h3 {
    font-family: "roboto";
    margin: 5px;
    font-size: 1rem;
    text-align: left;
    color: #fff;
  }
  
  .europeBox {
    margin: 5px;
    min-height: 200px;
    text-align: left;
    justify-content: left;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .europeBox h3 {
    font-family: "roboto";
    margin: 5px;
    font-size: 1rem;
    text-align: left;
    color: #fff;
  }
  .friendliesBox {
    margin: 5px;
    min-height: 200px;
    text-align: left;
    justify-content: left;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .friendliesBox h3 {
    font-family: "roboto";
  
    margin: 5px;
    font-size: 1rem;
    text-align: left;
    color: #fff;
  }
  .nationalsBox {
    margin: 5px;
    min-height: 200px;
    text-align: left;
    justify-content: left;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .nationalsBox h3 {
    font-family: "roboto";
  
    margin: 5px;
    font-size: 1rem;
    text-align: left;
    color: #fff;
  }
  .league5Box {
    margin: 5px;
    min-height: 200px;
    text-align: left;
    justify-content: left;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .league5Box h3 {
    font-family: "roboto";
  
    margin: 5px;
    font-size: 1rem;
    text-align: left;
    color: #fff;
  }
  .league6Box {
    margin: 5px;
    min-height: 200px;
    text-align: left;
    justify-content: left;
    align-items: left;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .league6Box h3 {
    font-family: "roboto";
  
    margin: 5px;
    font-size: 1rem;
    text-align: left;
    color: #fff;
  }
  
  /*Far rightside */
  .farRightSide {
    background-color: rgb(4, 5, 5, 0.8);
    margin: 30px;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .factBox {
    font-family: "VisbyBold";
    color: #ffdd00;
    margin: 5px;
  }
  
  .farRightSide p {
    font-family: "roboto";
    margin-left: 15px;
    margin-right: 15px;
    color: #fff;
  }
  .logoImage {
    margin: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .logoImageOmOss {
    margin: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    display: none;
  }
  
  .titleP {
    font-size: 0.7rem;
    margin: 4px;
    color: #fff;
  }
  .lineupP {
    font-family: "roboto";
    font-size: 1rem;
    color: #fff;
  }
  .dataBox {
    display: none;
  }
  .dataText {
    font-size: 0.8rem;
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
  }
  .nextGameVenue{
    text-align: center;
    color: #fff;
    margin:0;
    font-size: 1rem;
    font-family: "roboto";
  }
  .nextGameTime{
    text-align: center;
    color: #fff;
    font-size: 0.8rem;
    font-family: "roboto";
  }
  .nextGameDate{
    text-align: center;
    color: #fff;
    font-size: 0.8rem;
    font-family: "roboto";
    margin:0;
  }
  .nextGameTeams{
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    font-family: "VisbyBold";
    margin: 0;
  }
  .h2hTitle{
    font-family: "VisbyBold";
    margin: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 1rem;
    color: #ffdd00;
    text-align: center;
    justify-content: center;
  }
  .h2hTable{
    margin: 0px 0 10px 0px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    
    border-spacing: 0;
    border-collapse: collapse;
    overflow: hidden;
    
  
  }
  .h2hDate{
    font-size: 0.7rem;
    padding: 0 10px 0 10px;
    margin: 0;
    min-width: 40px;
    text-align: right;
  }
  .h2hP{
   
    font-size: 0.8rem;
    
    color: #fff;
    
    
  }
  .h2hResult{
    text-align: center;
    margin: 0;
    font-size: 0.8rem;
    
    
  }
  .h2hHome{
    margin-left:15px;
    text-align: right;
    min-width: 40px;
    padding: 5px;
    font-size: 0.8rem;
  
    
  }
  .h2hAway{
    text-align: right;
   padding: 5px;
    font-size: 0.8rem;
    min-width: 40px;
    
  }
  
  /* selecten  */
  .customSelect {
    height: 30px;
  
    background: #fff;
    background-size: 10px;
    transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
    border: 1px solid #ddd;
    border-radius: 3px;
  }
  .customSelect:hover {
    border: 1px solid #999;
  }
  .customSelect:focus {
    border: 1px solid #999;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
    outline: none;
  }
  /* remove default arrow in IE */
  select::-ms-expand {
    display: none;
  }
  
  .footerDiv {
    grid-column: 1 / span 3;
    margin-left: 0;
  }
  
  .tableBox {
    justify-content: center;
    text-align: center;
    background-clip: content-box;
    background-repeat: no-repeat;
    margin-bottom: 30px;
  }
  .selectDiv{
      text-align: center;
  }
  
  .tableSchema{
    margin: 30px 0 0px 0px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    background-color: rgb(4, 5, 5, 0.8);
    border-spacing: 0;
    border-collapse: collapse;
    overflow: hidden;
    box-shadow: 0 2px 12px rgba(32, 32, 32, 0.3);
  }
  th,
  td {
    color: white;
    font-family: roboto;
    padding: 5px 7px;
    font-size: 0.9rem;
  }
  th {
    color: black;
    font-family: roboto;
    background-color: rgb(255, 221, 0);
  }
  tr:nth-child(odd) {
    background-color: rgb(4, 5, 5, 0.8);
  }
  .rightTable {
    padding-right: 15px;
  }
  @media only screen and (max-width: 2200px) {
    .parent {
      margin-left: 300px;
      margin-right: 300px;
    }
  }
  @media only screen and (max-width: 2000px) {
    .parent {
      margin-left: 150px;
      margin-right: 150px;
    }
  }
  
  @media only screen and (max-width: 1500px) {
    .parent {
      margin: 30px;
    }
  }
  @media only screen and (max-width: 1100px) {
    
  }
  @media only screen and (max-width: 1000px) {
    .parent h1 {
      font-size: 1.5rem;
    }
    .parent {
      margin: 0;
    }
    .leftSide {
      margin: 5px;
    }
    .rightSide {
      margin: 5px;
    }
    .farRightSide {
      margin: 5px;
    }
    
    th,
    td {
   
      padding: 2px 2px;
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .parent p {
      font-size: 0.7rem;
      margin: 5px;
    }
    .fixtureP {
      font-size: 0.6rem;
    }
    th,
    td {
   
      padding: 0px 0px;
      font-size: 0.7rem;
    }
    
  }
  
  @media only screen and (max-width: 700px) {
    .parent {
      display: block;
    }
    .info {
      display: block;
      text-align: center;
      font-family: "VisbyBold";
    }
    .parent h1 {
      font-size: 2rem;
    }
    .rightSideFixtures {
      margin: 0;
    }
    .parent h3 {
      text-align: center;
    }
    .parent p {
      text-align: center;
    }
    .flexbox {
      justify-content: center;
      text-align: center;
    }
    .cupLogo {
      display: none;
    }
    .leftSide h3 {
      font-size: 1.6rem;
    }
    .parent p {
      font-size: 0.9rem;
    }
    .logoImage {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    .logoImageOmOss {
      display: block;
    }
    .borderBig {
      margin: 0px 15px 0px 15px;
    }
    .rightSide h1 {
      font-size: 2rem;
    }
    .farRightSide h1 {
      font-size: 2rem;
    }
    .leftSide .aikStats {
      display: block;
      text-align: center;
      color: #ffdd00;
    }
    .leftSide .emblemImage {
      display: block;
      margin: 0 auto;
      float: none;
      background-color: #fff;
      border: 5px solid #ffdd00;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .leftSide .catchPhrase {
      display: block;
    }
    .rightSide .emblemImage {
      display: none;
    }
    .rightSide .aikStats {
      display: none;
    }
    .rightSide .catchPhrase {
      display: none;
    }
    .footerDiv p {
      font-size: 0.7rem;
      margin: 0;
    }
    .dataBox {
      display: block;
    }
    .infoBox {
      display: none;
    }
    
  }
  
  @media only screen and (max-width: 450px) {
    .infoBox img {
      display: block;
      margin: 0 auto;
      float: none;
    }
    .infoBox h6 {
      margin: 10px;
    }
    .infoBox h1 {
      margin: 10px;
    }
    th,
    td {
      padding: 2px 2px;
    }
  }
  