@font-face {
  font-family: "roboto";
  src: url("../fonts/Roboto-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "VisbyBold";
  src: url("../fonts/VisbyCF-Bold.otf");
  font-display: swap;
}

.parentParent{
  background-image: url("../backgrounds/fvsw.png");
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
margin: 0;
padding: 0;
} 
.smallLink {
  text-decoration: none;
  color: #ffdd00;
}
.infoBox2{
  display: none;
}
.fischer{

  font-family: "VisbyBold";
}
.wikheim{
  
  font-family: "VisbyBold";
}
.parent {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-left: 550px;
  margin-right: 550px;
  background-color: rgb(240, 240, 239, 0.6);
  
}
.border {
  margin: 0px 15px 0px 15px;
  border-bottom: 1px solid rgb(106, 104, 104);
}
.borderBig {
  margin: 0px 15px 0px 200px;
  border-bottom: 2px solid rgb(5, 5, 5);
}
.borderBox {
  margin: 0px 15px 0px 0px;
  border-bottom: 1px solid rgb(106, 104, 104);
}
.borderBoxLast {
  margin: 0px 15px 10px 0px;
  padding-top: 5px;
  border-bottom: 1px solid rgb(106, 104, 104);
}
.cupLogo {
  margin-right: 20px;
  float: right;
  width: 60px;
  color: yellow;
}
.boxesImage {
  width: 50px;
  padding: 5px;
  filter: invert(90%) sepia(24%) saturate(3900%) hue-rotate(358deg)
    brightness(102%) contrast(105%);

  display: block;
  margin-left: auto;
  margin-right: auto;
}
/* leftSide */

.leftSide {
  margin: 30px;
  background-color: rgb(4, 5, 5, 0.8);
  min-height: 100vh;
}
.leftSideTop{
  text-align: center;
}
.factBoxLeft{
   font-family: "VisbyBold";
   font-size: 1.2rem;
  margin: 15px;
  color: #ffdd00;
}
.factBoxDiv{
  text-align: center;
}
.nationality2{
margin: 5px;
}
.flexbox {
  display: flex;
}
.leftSide p {
  font-family: "roboto";
  margin-left: 15px;
  margin-right: 15px;
  color: #fff;
}

.leftSide h3 {
  margin: 2px;
  margin-left: 15px;
}
.playerName {
  font-family: "VisbyBold";
  margin: 15px;
  color: #ffdd00;
}
.bigMenu {
  color: #ffdd00;
}

/* leftSide END*/

.rightSide {
}
.signing {
  font-family: "roboto";
  font-size: 1rem;
  color: #222;
  margin-top: 10px;
}

.upperBox {
}
.infoBox {
  width: auto;
}
.playerImage {
  margin-right: 20px;
  margin-top: 30px;
  float: left;
  border-radius: 200px;
  box-sizing: border-box;
  background-color: #ddd;
  border: 5px solid #000000;
}
.playerImages {
  margin-right: 20px;
  margin-top: 30px;
  border-radius: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  background-color: #ddd;
  border: 5px solid #ffdd00;
}
.playerImages2 {
  margin-right: 20px;
  margin-top: 30px;
  border-radius: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  background-color: #ddd;
  border: 5px solid #32baf2;
}
.selectador {
  display: block;
  margin: 0 auto;
}
.nationality {
  margin-top: 40px;
  color: #222;
}
.rightSide p {
  margin-top: 40px;

  float: right;
  color: #222;
}
.rightSide h1 {
  font-size: 1.5rem;
  margin-top: 40px;
}
.signingTitle {
  font-family: "VisbyBold";
  font-size: 80px;
  margin: 0;

  float: right;
  padding-left: 40px;
}
.signingTitleMobile {
  display: none;
}
.playerNameBig {
  position: relative;
  text-align: right;
  font-family: "VisbyBold";
  font-size: 1.3rem;
  margin: 0;
  margin-top: 165px;
  float: right;
}

.bigNumber {
  font-size: 5rem;
  color: #ffdd00;
  -webkit-text-stroke: 1px black;
}

.rightSide h4 {
  font-size: 0.9rem;
  font-weight: normal;
  color: #222;
}

.compInfo {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 380px;
}

/*Far rightside */
.farRightSide {
  background-color: rgb(4, 5, 5, 0.8);
  margin: 30px;
  min-height: 100vh;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.factBoxRight{
  font-family: "VisbyBold";
  font-size: 1.2rem;
 margin: 15px;
 color: #32baf2;
}
.playerName2 {
  font-family: "VisbyBold";
  margin: 15px;
  color: #32baf2;
}
.bigMenu2 {
  color: #32baf2;
  text-align: left;
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 5px;
}
.bigMenu3 {
  color: #32baf2;
  text-align: center;
  margin-left: 15px;
  margin-top: 0;
  margin-bottom: 5px;
}
.factBox {
  font-family: "VisbyBold";
  color: #ffdd00;
  margin: 5px;
}

.farRightSide p {
  font-family: "roboto";

  margin: 15px;
  color: #fff;
}
.logoImage {
  margin: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.titleP {
  font-size: 0.6rem;
  color: #fff;
}
.dataBox {
  display: none;
}
.dataText {
  font-size: 0.8rem;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
}
.nationality3{
  margin: 5px;
  color: #fff;
  font-family: "roboto";
  font-size: 1rem;
  font-weight: normal;
  }

/* selecten  */
.customSelect {
  height: 30px;

  background: #fff;
  background-size: 10px;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.customSelect:hover {
  border: 1px solid #999;
}
.customSelect:focus {
  border: 1px solid #999;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);
  outline: none;
}
/* remove default arrow in IE */
select::-ms-expand {
  display: none;
}

.footerDiv {
  grid-column: 1 / span 3;
  margin-left: 0;
}
@media only screen and (max-width: 2200px) {
  .parent {
    margin-left: 300px;
    margin-right: 300px;
  }
}
@media only screen and (max-width: 2000px) {
  .parent {
    margin-left: 150px;
    margin-right: 150px;
  }
}

@media only screen and (max-width: 1500px) {
  .parent {
    margin: 0;
  }
}
@media only screen and (max-width: 1250px) {
    .nameBox{
        display: none;
    }
  }
@media only screen and (max-width: 1100px) {
 
}
@media only screen and (max-width: 1000px) {
  .parent h1 {
    font-size: 1.5rem;
  }
  .parent {
    margin: 0;
  }
  .leftSide {
    margin: 5px;
  }
  .rightSide {
    margin: 5px;
  }
  .farRightSide {
    margin: 5px;
  }
}
@media only screen and (max-width: 800px) {
  .parent p {
  
    margin: 5px;
  }
  .fixtureP {
    font-size: 0.6rem;
  }
}

@media only screen and (max-width: 700px) {
  .body{
    margin: 0;
    padding: 0;
  }
  .infoBox2{
    display: block;
  }
  .info{
    color: #fff;
  }
  .fischer{
    color: #ffdd00;
    font-family: "VisbyBold";
  }
  .wikheim{
    color: #32baf2;
    font-family: "VisbyBold";
  }
  .signing{
    color: #fff;
    margin: 15px;
    font-weight: normal;
  }
  .parent {
    display: block;
  }
  .parent h1 {
    font-size: 2rem;
  }
  .rightSideFixtures {
    margin: 0;
  }
  .parent h3 {
    text-align: center;
  }
  .parent p {
    font-size: 0.7rem;
    text-align: center;
  }
  .flexbox {
    justify-content: center;
    text-align: center;
  }
  .cupLogo {
    display: none;
  }
  .parent p {
    font-size: 0.9rem;
  }
  .logoImage {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .rightSide h1 {
    font-size: 2rem;
  }
  .farRightSide h1 {
    font-size: 2rem;
  }
  .playerName {
    text-align: center;
  }
  .cupTitle {
    text-align: center;
  }
  .borderBig {
    margin: 0px 15px 0px 15px;
  }
  .infoBox img {
    display: none;
  }
  .leftSide .playerImage {
    display: block;
    margin: 0 auto;
    float: none;
    border: 5px solid #ffdd00;
    padding: 10px;
  }
  .infoBox h1 {
    text-align: center;
  }
  .compInfo {
    margin: 0;
  }
  .footerDiv p {
    font-size: 0.7rem;
    margin: 0;
  }
  .borderBox {
    margin: 0px 15px 0px 15px;
  }
  .borderBoxLast {
    margin: 0px 15px 10px 15px;
    padding-top: 5px;
  }
  .dataBox {
    display: block;
  }
  .infoBox {
    display: none;
  }
  .signingTitleMobile {
    font-family: "VisbyBold";
    text-align: center;
    font-size: 80px;
    margin: 0;
    color: #ffdd00;
    display: block;
  }
}

@media only screen and (max-width: 450px) {
  .infoBox h6 {
    margin: 10px;
  }
  .infoBox h1 {
    margin: 10px;
  }

  .farRightSide p {
    font-size: 0.9rem;
  }
}
