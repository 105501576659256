@font-face {
    font-family: "stickler";
    src: url("../fonts/Rydero.ttf");
    font-display: swap;
  }

.parentDiv{
    min-height: 50px;
    background-color: rgb(4, 5, 5, 0.8);
    text-align: center;
   
}
.middle{
    
    text-align: center;
    color: #ffdd00;
}
.middle h1{
    padding-top: 20px;
    font-family: "VisbyBold";
    margin-bottom: 0;
}

.catchPhrase{
    font-size: 0.7rem;
    font-style: roboto;
    color: #fff;
    margin: 5px;
}
.mail{
    font-size: 0.7rem;
    font-style: roboto;
    color: #fff;
    margin-right:5px;
    margin-bottom: 0;
}

.powered{
    font-size: 0.7rem;
    font-style: roboto;
   color: #fff;
   margin: 0;
   margin-right:5px;

padding-bottom:5px;
}
.twitter{
    color: #1DA1F2;
    font-size: 2rem;
    margin: 10px;
}
.twitter:hover{
   color: #ffdd00;
   transition: 750ms;
}

.borderBox{
    margin: 0px 250px 0px 250px;
            border-bottom: 1px solid rgb(106, 104, 104);
}
@media only screen and (max-width: 700px) {
    .parentDiv{
        margin: 5px;
    }
    .middle h1{ 
        font-size: 2rem;
    }
    .borderBox{
        margin: 0px 10px 0px 10px;
    }
    .mail{
        font-size: 0.7rem;
    }
}